<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        :md="showHelp ? 8 : 11"
        :sm="12"
        v-show="!showExpand"
        class="pr-0"
      >
        <GoBackBtn :routeToGo="routeToGo" :tabId="false" />
        <PageHeader :title="title" />
        <v-data-table
          :headers="headers"
          :items="especialidades"
          class="elevation-1"
          :search="search"
          :loading="isLoading"
        >
          <template v-slot:top>
            <v-toolbar flat>
              <v-row>
                <v-col cols="10">
                  <v-text-field
                    v-model="search"
                    :append-icon="searchIcon"
                    label="Buscar"
                    single-line
                    hide-details
                  ></v-text-field>
                </v-col>
                <v-col cols="2" align="end" v-if="canCreate">
                  <v-btn color="primary" @click="openModal()" class="to-right">
                    Nuevo
                  </v-btn>
                </v-col>
              </v-row>
            </v-toolbar>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-tooltip left v-if="canEdit">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="openModal(item)"
                >
                  {{ editIcon }}
                </v-icon>
              </template>
              <span>Editar especialidad</span>
            </v-tooltip>
            <v-tooltip left v-if="canDelete">
              <template v-slot:activator="{ on, attrs }">
                <v-icon
                  v-bind="attrs"
                  size="20"
                  v-on="on"
                  @click="removeEspecialidad(item.espId)"
                >
                  {{ deleteIcon }}
                </v-icon>
              </template>
              <span>Eliminar especialidad</span>
            </v-tooltip>
          </template>
        </v-data-table>
      </v-col>
      <!-- Texto de ayuda principal -->
      <v-col
        cols="12"
        :md="!showHelp ? 1 : showExpand ? 12 : 4"
        class="to-right pt-5 px-0 "
      >
        <Ayuda
          :optionCode="optionCode"
          :pantallaTitle="title"
          @toggleExpand="showExpand = !showExpand"
          @toggleHelp="toggleHelp()"
          @toggleIcon="toggleIcon()"
        />
      </v-col>
    </v-row>
    <v-dialog
      v-if="openModalEdit"
      v-model="openModalEdit"
      max-width="400px"
      @keydown.esc="closeModal"
      persistent
    >
      <EditEspecialidades
        :especialidadObj="especialidadObj"
        @closeAndReload="closeAndReload"
      ></EditEspecialidades>
    </v-dialog>
    <DeleteDialog
      :titleProp="titleDelete"
      :maxWidth="'25%'"
      :isLoading="loadingDeleteBtn"
      :openDelete.sync="showDeleteModal"
      @onDeleteItem="confirmDelete()"
    />
  </v-container>
</template>
<script>
import PageHeader from "@/components/ui/PageHeader";
import DeleteDialog from "@/components/shared/DeleteDialog";
import EditEspecialidades from "@/components/modules/prestadores/EditEspecialidades.vue";
import enums from "@/utils/enums/index.js";
import rules from "@/utils/helpers/rules";
import { mapActions } from "vuex";
import GoBackBtn from "@/components/shared/GoBackBtn";
import Ayuda from "@/components/shared/Ayuda.vue";

export default {
  name: "Especialidades",
  components: {
    PageHeader,
    DeleteDialog,
    GoBackBtn,
    EditEspecialidades,
    Ayuda
  },
  data() {
    return {
      rules: rules,
      optionCode: enums.webSiteOptions.ESPECIALIDADES,
      searchIcon: enums.icons.SEARCH,
      editIcon: enums.icons.EDIT,
      deleteIcon: enums.icons.DELETE,
      title: enums.titles.ESPECIALIDADES,
      titleDelete: enums.titles.DELETE_ESPECIALIDAD,
      routeToGo: "ConfiguracionPrestadores",
      especialidades: [],
      especialidadObj: {},
      search: "",
      allowedActions: null,
      showHelp: false,
      showExpand: false,
      canCreate: false,
      canDelete: false,
      canEdit: false,
      loadingDeleteBtn: false,
      openModalEdit: false,
      showDeleteModal: false,
      isLoading: false,
      headers: [
        {
          text: "Nombre",
          value: "espNom",
          descripcion: "",
          sortable: false
        },
        {
          text: "Acciones",
          value: "actions",
          descripcion: "",
          sortable: false,
          align: "end"
        }
      ]
    };
  },
  async created() {
    this.$store.dispatch("user/changeAppLoading", {
      status: false,
      text: "",
      opaque: false
    });
    this.getEspecialidades();
  },
  mounted() {
    this.allowedActions = JSON.parse(localStorage.getItem("allowedActions"));
    this.setPermisos();
  },
  methods: {
    ...mapActions({
      getPrestadoresEspecialidades: "prestadores/getPrestadoresEspecialidades",
      deleteEspecialidad: "prestadores/deleteEspecialidad",
      setAlert: "user/setAlert"
    }),
    setPermisos() {
      this.allowedActions?.forEach(x => {
        switch (x.optionCode) {
          case enums.modules.adminSistema.permissions.NUEVA_ESPECIALIDAD:
            this.canCreate = true;
            break;
          case enums.modules.adminSistema.permissions.EDITAR_ESPECIALIDAD:
            this.canEdit = true;
            break;
          case enums.modules.adminSistema.permissions.ELIMINAR_ESPECIALIDAD:
            this.canDelete = true;
            break;
          default:
            break;
        }
      });
    },
    async getEspecialidades() {
      this.isLoading = true;
      const response = await this.getPrestadoresEspecialidades();
      this.especialidades = response;
      this.isLoading = false;
    },
    openModal(item) {
      this.openModalEdit = true;
      this.especialidadObj = item;
    },
    closeModal() {
      this.openModalEdit = false;
    },
    closeAndReload() {
      this.openModalEdit = false;
      this.getEspecialidades();
    },
    removeEspecialidad(id) {
      this.showDeleteModal = true;
      this.idToDelete = id;
    },
    async confirmDelete() {
      this.loadingDeleteBtn = true;
      try {
        const response = await this.deleteEspecialidad(this.idToDelete);
        if (response.status === 200) {
          this.showDeleteModal = false;
          this.setAlert({ type: "success", message: "Eliminado con éxito." });
          this.getEspecialidades();
          this.loadingDeleteBtn = false;
        }
      } catch (error) {
        this.showDeleteModal = false;
        this.loadingDeleteBtn = false;
      }
    },
    toggleHelp() {
      this.showHelp = !this.showHelp;
      this.showHelp ? [] : (this.showExpand = false);
    },
    toggleIcon() {
      this.showIcon = true;
    }
  }
};
</script>
